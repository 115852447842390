import React from "react"
import Layout from "~components/layouts/default"
import SEO from "~components/common/seo"
import Container from "~components/common/container"

export default ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />

      <Container>
        <div className="gutter my-screen-lg lg:w-3/4 lg:mx-auto xl:w-2/3">
          <h1>{frontmatter.title}</h1>
          <div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Container>
    </Layout>
  )
}
